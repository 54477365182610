<template>
  <div class="partners">
    <Slick
      class="partners__slider"
      :dots="true"
      :arrows="false"
      :responsive="[
        {
          breakpoint: 10000000,
          settings: 'unslick'
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]"
    >
      <div
        v-for="({ name, pic }, index) in partners"
        :key="index"
        class="partner partners__item"
      >
        <div class="partner__icon">
          <img :src="pic" :alt="name" />
        </div>
        <h6 class="partner__name">
          {{ name }}
        </h6>
      </div>
    </Slick>
  </div>
</template>

<script>
import './partners.scss';

export default {
  name: 'Partners',
  data() {
    return {
      partners: [
        {
          name: 'Госуслуги',
          pic: require('./assets/icons/1.png')
        },
        {
          name: 'Реестр Банкротов',
          pic: require('./assets/icons/2.png')
        },
        {
          name: 'Министерство экономического развития РФ',
          pic: require('./assets/icons/3.png')
        },
        {
          name: 'МВД',
          pic: require('./assets/icons/4.png')
        },
        {
          name: 'МФЦ',
          pic: require('./assets/icons/5.png')
        },
        {
          name: 'ФССП',
          pic: require('./assets/icons/6.png')
        }
      ]
    };
  }
};
</script>
