var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"partners"},[_c('Slick',{staticClass:"partners__slider",attrs:{"dots":true,"arrows":false,"responsive":[
      {
        breakpoint: 10000000,
        settings: 'unslick'
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]}},_vm._l((_vm.partners),function(ref,index){
    var name = ref.name;
    var pic = ref.pic;
return _c('div',{key:index,staticClass:"partner partners__item"},[_c('div',{staticClass:"partner__icon"},[_c('img',{attrs:{"src":pic,"alt":name}})]),_c('h6',{staticClass:"partner__name"},[_vm._v(" "+_vm._s(name)+" ")])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }